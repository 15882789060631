var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "fb-page",
    [
      _c(
        "fb-header",
        { attrs: { slot: "header", title: "开票", fixed: "" }, slot: "header" },
        [
          _c(
            "fb-button",
            {
              attrs: {
                slot: "left",
                icon: "back",
                size: "small",
                type: "primary",
              },
              on: {
                click: function ($event) {
                  return _vm.backList(0)
                },
              },
              slot: "left",
            },
            [_vm._v(" 返回 ")]
          ),
        ],
        1
      ),
      _c(
        "van-popup",
        {
          attrs: { position: "top", "get-container": "body" },
          model: {
            value: _vm.caseListVisible,
            callback: function ($$v) {
              _vm.caseListVisible = $$v
            },
            expression: "caseListVisible",
          },
        },
        [
          _c("case-list", {
            attrs: { caseId: _vm.caseId },
            on: { select: _vm.handleCaseListSelect },
          }),
        ],
        1
      ),
      _c(
        "van-popup",
        {
          attrs: { position: "top", "get-container": "body" },
          model: {
            value: _vm.itemListVisible,
            callback: function ($$v) {
              _vm.itemListVisible = $$v
            },
            expression: "itemListVisible",
          },
        },
        [
          _c("stamp-type", {
            attrs: {
              options: _vm.itemList,
              radio: _vm.item,
              title: "开票项目",
            },
            on: { select: _vm.handleItemListSelect },
          }),
        ],
        1
      ),
      _c(
        "van-popup",
        {
          attrs: { position: "top", "get-container": "body" },
          model: {
            value: _vm.invoiceTypeListVisible,
            callback: function ($$v) {
              _vm.invoiceTypeListVisible = $$v
            },
            expression: "invoiceTypeListVisible",
          },
        },
        [
          _c("stamp-type", {
            attrs: {
              options: _vm.invoiceTypeList,
              radio: _vm.type,
              title: "发票类型",
            },
            on: { select: _vm.handleInvoiceTypeListSelect },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "oa-add-invoice" },
        [
          _c("div", { staticClass: "oa-add-invoice-top" }, [
            _c(
              "div",
              {
                staticClass: "top-invoice-title",
                on: { click: _vm.switchCaseList },
              },
              [
                _vm.caseName === ""
                  ? _c("div", { staticStyle: { color: "#999" } }, [
                      _vm._v("请选择案件"),
                    ])
                  : _c("div", [_vm._v(_vm._s(_vm.caseName))]),
              ]
            ),
            _vm.caseName !== "" && _vm.isShow
              ? _c("div", { staticClass: "top-invoice-content" }, [
                  _c("div", { staticClass: "invoice-info" }, [
                    _c("div", [_vm._v(_vm._s(_vm.caseNo))]),
                    _c("div", [_vm._v(_vm._s(_vm.acceptCaseTime) + "收案")]),
                  ]),
                  _c("div", { staticClass: "invoice-info" }, [
                    _c("div", [_vm._v("收费")]),
                    _c("div", [_vm._v(_vm._s(_vm.price.service) + "元")]),
                  ]),
                  _c("div", { staticClass: "invoice-info" }, [
                    _c("div", [
                      _vm._v("已收： " + _vm._s(_vm.price.received) + "元"),
                    ]),
                    _c("div", [
                      _vm._v("未收： " + _vm._s(_vm.price.uncollected) + "元"),
                    ]),
                  ]),
                  _c("div", { staticClass: "invoice-info" }, [
                    _c("div", [
                      _vm._v(" 未开票："),
                      _c("span", { staticStyle: { color: "#000000" } }, [
                        _vm._v(_vm._s(_vm.price.invoice.uninvoiced) + "元"),
                      ]),
                    ]),
                    _c(
                      "div",
                      {
                        staticStyle: { color: "#0079FE" },
                        on: { click: _vm.switchShow },
                      },
                      [_vm._v("收起")]
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm.caseName !== "" && _vm.isShow === false
              ? _c("div", { staticClass: "top-invoice-content" }, [
                  _c("div", { staticClass: "invoice-info" }, [
                    _c("div", [
                      _vm._v(" 未开票："),
                      _c("span", { staticStyle: { color: "#000000" } }, [
                        _vm._v(_vm._s(_vm.price.invoice.uninvoiced) + "元"),
                      ]),
                    ]),
                    _c(
                      "div",
                      {
                        staticStyle: { color: "#0079FE" },
                        on: { click: _vm.switchShow },
                      },
                      [_vm._v("展开")]
                    ),
                  ]),
                ])
              : _vm._e(),
          ]),
          _c("div", { staticClass: "oa-add-invoice-line" }),
          _c("div", { staticClass: "oa-add-invoice-center" }, [
            _c("div", { staticClass: "invoice-info" }, [
              _c("div", { staticClass: "invoice-info-title" }, [
                _c("span", { staticClass: "invoice-info-title-red" }, [
                  _vm._v("*"),
                ]),
                _vm._v("发票抬头 "),
              ]),
              _c(
                "div",
                { staticClass: "invoice-info-title-input" },
                [
                  _c("van-field", {
                    attrs: {
                      placeholder: "请输入发票抬头",
                      type: "text",
                      "input-align": "right",
                    },
                    on: {
                      focus: _vm.onFocus,
                      blur: _vm.onBlur,
                      input: _vm.searchInput,
                    },
                    model: {
                      value: _vm.title,
                      callback: function ($$v) {
                        _vm.title = $$v
                      },
                      expression: "title",
                    },
                  }),
                  _vm.companyList.length
                    ? _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.companyShow,
                              expression: "companyShow",
                            },
                          ],
                          staticClass: "search-suggestion",
                        },
                        _vm._l(_vm.companyList, function (p, i) {
                          return _c(
                            "div",
                            {
                              key: i,
                              staticClass: "suggestion-list-item",
                              on: {
                                click: function ($event) {
                                  return _vm.select(p)
                                },
                              },
                            },
                            [
                              _c("div", { staticClass: "name" }, [
                                _vm._v(_vm._s(p.companyName || p.litigantName)),
                              ]),
                              p.procedureSubjectShort
                                ? _c("div", { staticClass: "attr tag" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(p.procedureSubjectShort) +
                                        " "
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "invoice-info" }, [
              _c("div", { staticClass: "invoice-info-title" }, [
                _c("span", { staticClass: "invoice-info-title-red" }, [
                  _vm._v("*"),
                ]),
                _vm._v("开票金额 "),
              ]),
              _c(
                "div",
                [
                  _c("van-field", {
                    attrs: {
                      placeholder: "请输入开票金额",
                      readonly: "",
                      clickable: "",
                      "input-align": "right",
                    },
                    on: {
                      touchstart: function ($event) {
                        $event.stopPropagation()
                        _vm.show = true
                      },
                    },
                    model: {
                      value: _vm.money,
                      callback: function ($$v) {
                        _vm.money = $$v
                      },
                      expression: "money",
                    },
                  }),
                  _c("van-number-keyboard", {
                    attrs: {
                      show: _vm.show,
                      theme: "custom",
                      "extra-key": ".",
                      "close-button-text": "完成",
                    },
                    on: {
                      blur: function ($event) {
                        _vm.show = false
                      },
                      input: _vm.onInput,
                      delete: _vm.onDelete,
                    },
                  }),
                ],
                1
              ),
            ]),
            _c(
              "div",
              {
                staticClass: "invoice-info",
                on: { click: _vm.swtichItemList },
              },
              [
                _c("div", { staticClass: "invoice-info-title" }, [
                  _c("span", { staticClass: "invoice-info-title-red" }, [
                    _vm._v("*"),
                  ]),
                  _vm._v("开票项目 "),
                ]),
                _c(
                  "div",
                  [
                    _vm._v(" " + _vm._s(_vm.item) + " "),
                    _c("van-icon", {
                      attrs: { name: "arrow-down", color: "#CCCCCC" },
                    }),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "invoice-info",
                on: { click: _vm.swtichInvoiceTypeList },
              },
              [
                _c("div", { staticClass: "invoice-info-title" }, [
                  _c("span", { staticClass: "invoice-info-title-red" }, [
                    _vm._v("*"),
                  ]),
                  _vm._v("发票类型 "),
                ]),
                _c(
                  "div",
                  [
                    _vm._v(" " + _vm._s(_vm.typeText) + " "),
                    _c("van-icon", {
                      attrs: { name: "arrow-down", color: "#CCCCCC" },
                    }),
                  ],
                  1
                ),
              ]
            ),
            _c("div", { staticClass: "invoice-info" }, [
              _c("div", { staticClass: "invoice-info-title" }, [
                _vm._v("纳税人识别号"),
              ]),
              _c(
                "div",
                [
                  _c("van-field", {
                    attrs: { placeholder: "请输入", "input-align": "right" },
                    model: {
                      value: _vm.taxpayersRegistrationNumber,
                      callback: function ($$v) {
                        _vm.taxpayersRegistrationNumber = $$v
                      },
                      expression: "taxpayersRegistrationNumber",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "invoice-info" }, [
              _c("div", { staticClass: "invoice-info-title" }, [
                _vm._v("注册场所地址"),
              ]),
              _c(
                "div",
                [
                  _c("van-field", {
                    attrs: { placeholder: "请输入", "input-align": "right" },
                    model: {
                      value: _vm.address,
                      callback: function ($$v) {
                        _vm.address = $$v
                      },
                      expression: "address",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "invoice-info" }, [
              _c("div", { staticClass: "invoice-info-title" }, [
                _vm._v("注册固定电话"),
              ]),
              _c(
                "div",
                [
                  _c("van-field", {
                    attrs: { placeholder: "请输入", "input-align": "right" },
                    model: {
                      value: _vm.phone,
                      callback: function ($$v) {
                        _vm.phone = $$v
                      },
                      expression: "phone",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "invoice-info" }, [
              _c("div", { staticClass: "invoice-info-title" }, [
                _vm._v("基本开户银行名称"),
              ]),
              _c(
                "div",
                [
                  _c("van-field", {
                    attrs: { placeholder: "请输入", "input-align": "right" },
                    model: {
                      value: _vm.openingBank,
                      callback: function ($$v) {
                        _vm.openingBank = $$v
                      },
                      expression: "openingBank",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "invoice-info" }, [
              _c("div", { staticClass: "invoice-info-title" }, [
                _vm._v("基本开户账号"),
              ]),
              _c(
                "div",
                [
                  _c("van-field", {
                    attrs: { placeholder: "请输入", "input-align": "right" },
                    model: {
                      value: _vm.bankAccount,
                      callback: function ($$v) {
                        _vm.bankAccount = $$v
                      },
                      expression: "bankAccount",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _c("div", { staticClass: "oa-add-invoice-line" }),
          _vm.progress === "UNAUDITED" ||
          _vm.progress === "APPROVED" ||
          _vm.progress === "CERTIFIED_FAILED"
            ? _c(
                "div",
                { staticClass: "oa-approval" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "oa-approval-title",
                      staticStyle: { "margin-bottom": "20px" },
                    },
                    [_vm._v("审批记录")]
                  ),
                  _c("approval-record", {
                    attrs: { typeId: this.id, type: "INVOICE_RECORD_ADD" },
                  }),
                ],
                1
              )
            : _vm.approvalUserComp
            ? _c("approval-user", {
                attrs: {
                  Approval: this.Approval,
                  CC: this.CC,
                  isAdd: _vm.isAdd,
                  approvalUserSource: _vm.approvalUser,
                  ccUserSource: _vm.ccUser,
                },
                on: { userSelect: _vm.userSelect, coopSelect: _vm.coopSelect },
              })
            : _vm._e(),
          _vm.operateBtnShow
            ? _c("operate-btn", {
                attrs: {
                  isAdd: _vm.isAdd,
                  progress: this.progress,
                  approveRecordUsers: this.invoice.approveRecordUsers,
                  Approval: this.Approval,
                  CC: this.CC,
                },
                on: { save: _vm.save },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }