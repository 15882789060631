<template>
  <fb-page>
    <!-- 头部插槽 -->
    <fb-header slot="header" title="开票" fixed>
      <fb-button
        slot="left"
        icon="back"
        @click="backList(0)"
        size="small"
        type="primary"
      >
        返回
      </fb-button>
      <!-- <div slot="right">
        查看本案开票记录
      </div> -->
    </fb-header>
    <!-- 案件列表 -->
    <van-popup v-model="caseListVisible" position="top" get-container="body">
      <case-list :caseId="caseId" @select="handleCaseListSelect" />
    </van-popup>
    <!-- 开票项目 -->
    <van-popup v-model="itemListVisible" position="top" get-container="body">
      <stamp-type
        :options="itemList"
        :radio="item"
        title="开票项目"
        @select="handleItemListSelect"
      />
    </van-popup>
    <!-- 发票类型 -->
    <van-popup
      v-model="invoiceTypeListVisible"
      position="top"
      get-container="body"
    >
      <stamp-type
        :options="invoiceTypeList"
        :radio="type"
        title="发票类型"
        @select="handleInvoiceTypeListSelect"
      />
    </van-popup>
    <div class="oa-add-invoice">
      <div class="oa-add-invoice-top">
        <div class="top-invoice-title" @click="switchCaseList">
          <div v-if="caseName === ''" style="color:#999">请选择案件</div>
          <div v-else>{{ caseName }}</div>
        </div>
        <div class="top-invoice-content" v-if="caseName !== '' && isShow">
          <div class="invoice-info">
            <div>{{ caseNo }}</div>
            <div>{{ acceptCaseTime }}收案</div>
          </div>
          <div class="invoice-info">
            <div>收费</div>
            <div>{{ price.service }}元</div>
          </div>
          <div class="invoice-info">
            <div>已收： {{ price.received }}元</div>
            <div>未收： {{ price.uncollected }}元</div>
          </div>
          <div class="invoice-info">
            <div>
              未开票：<span style="color:#000000"
                >{{ price.invoice.uninvoiced }}元</span
              >
            </div>
            <div style="color:#0079FE" @click="switchShow">收起</div>
          </div>
        </div>
        <div
          class="top-invoice-content"
          v-if="caseName !== '' && isShow === false"
        >
          <div class="invoice-info">
            <div>
              未开票：<span style="color:#000000"
                >{{ price.invoice.uninvoiced }}元</span
              >
            </div>
            <div style="color:#0079FE" @click="switchShow">展开</div>
          </div>
        </div>
      </div>
      <div class="oa-add-invoice-line"></div>
      <div class="oa-add-invoice-center">
        <div class="invoice-info">
          <div class="invoice-info-title">
            <span class="invoice-info-title-red">*</span>发票抬头
          </div>
          <div class="invoice-info-title-input">
            <van-field
              v-model="title"
              placeholder="请输入发票抬头"
              type="text"
              @focus="onFocus"
              @blur="onBlur"
              input-align="right"
              @input="searchInput"
            />
            <div
              class="search-suggestion"
              v-show="companyShow"
              v-if="companyList.length"
            >
              <div
                class="suggestion-list-item"
                v-for="(p, i) in companyList"
                :key="i"
                @click="select(p)"
              >
                <div class="name">{{ p.companyName || p.litigantName }}</div>
                <div class="attr tag" v-if="p.procedureSubjectShort">
                  {{ p.procedureSubjectShort }}
                </div>
              </div>
            </div>
          </div>

          <!-- <div style="color:#CCCCCC" v-if="title === ''">
            请输入发票抬头
            <van-icon name="arrow-down" color="#CCCCCC" />
          </div>
          <div v-else>
            {{ title }}
            <van-icon name="arrow-down" color="#CCCCCC" />
          </div> -->
        </div>
        <div class="invoice-info">
          <div class="invoice-info-title">
            <span class="invoice-info-title-red">*</span>开票金额
          </div>
          <div>
            <van-field
              v-model="money"
              placeholder="请输入开票金额"
              readonly
              clickable
              input-align="right"
              @touchstart.stop="show = true"
            />
            <van-number-keyboard
              :show="show"
              theme="custom"
              extra-key="."
              close-button-text="完成"
              @blur="show = false"
              @input="onInput"
              @delete="onDelete"
            />
          </div>
        </div>
        <div class="invoice-info" @click="swtichItemList">
          <div class="invoice-info-title">
            <span class="invoice-info-title-red">*</span>开票项目
          </div>
          <div>
            {{ item }}
            <van-icon name="arrow-down" color="#CCCCCC" />
          </div>
        </div>
        <div class="invoice-info" @click="swtichInvoiceTypeList">
          <div class="invoice-info-title">
            <span class="invoice-info-title-red">*</span>发票类型
          </div>
          <div>
            {{ typeText }}
            <van-icon name="arrow-down" color="#CCCCCC" />
          </div>
        </div>
        <div class="invoice-info">
          <div class="invoice-info-title">纳税人识别号</div>
          <div>
            <van-field
              v-model="taxpayersRegistrationNumber"
              placeholder="请输入"
              input-align="right"
            />
          </div>
        </div>
        <div class="invoice-info">
          <div class="invoice-info-title">注册场所地址</div>
          <div>
            <van-field
              v-model="address"
              placeholder="请输入"
              input-align="right"
            />
          </div>
        </div>
        <div class="invoice-info">
          <div class="invoice-info-title">注册固定电话</div>
          <div>
            <van-field
              v-model="phone"
              placeholder="请输入"
              input-align="right"
            />
          </div>
        </div>
        <div class="invoice-info">
          <div class="invoice-info-title">基本开户银行名称</div>
          <div>
            <van-field
              v-model="openingBank"
              placeholder="请输入"
              input-align="right"
            />
          </div>
        </div>
        <div class="invoice-info">
          <div class="invoice-info-title">基本开户账号</div>
          <div>
            <van-field
              v-model="bankAccount"
              placeholder="请输入"
              input-align="right"
            />
          </div>
        </div>
      </div>
      <div class="oa-add-invoice-line"></div>

      <div
        class="oa-approval"
        v-if="
          progress === 'UNAUDITED' ||
            progress === 'APPROVED' ||
            progress === 'CERTIFIED_FAILED'
        "
      >
        <div class="oa-approval-title" style="margin-bottom:20px">审批记录</div>
        <approval-record :typeId="this.id" :type="'INVOICE_RECORD_ADD'" />
      </div>
      <approval-user
        v-else-if="approvalUserComp"
        :Approval="this.Approval"
        :CC="this.CC"
        :isAdd="isAdd"
        :approvalUserSource="approvalUser"
        :ccUserSource="ccUser"
        @userSelect="userSelect"
        @coopSelect="coopSelect"
      />
      <operate-btn
        v-if="operateBtnShow"
        :isAdd="isAdd"
        :progress="this.progress"
        :approveRecordUsers="this.invoice.approveRecordUsers"
        :Approval="this.Approval"
        :CC="this.CC"
        @save="save"
      />
    </div>
  </fb-page>
</template>

<script>
import goBackMixin from '@mixins/goBack'

import { mapState, mapActions, mapMutations } from 'vuex'
import CaseList from '../../components/caseList'
import ApprovalUser from '../../components/approvalUser'
import StampType from '../../components/stampType'
import ApprovalRecord from '../../components/approvalRecord'
import OperateBtn from '../../components/operateBtn'

export default {
  name: 'oa-add-invoice',
  mixins: [goBackMixin],
  components: {
    ApprovalRecord,
    CaseList,
    StampType,
    ApprovalUser,
    OperateBtn
  },
  data() {
    return {
      show: false,
      id: 0,
      isShow: true, // 是否展开更多
      companyListVisible: false, // 公司列表弹框
      caseListVisible: false, // 案件列表弹框
      caseName: '', // 案件名称
      caseId: 0, // 案件id
      price: {
        service: 0,
        subsidy: 0,
        reduced: 0,
        receivable: 0,
        received: 0,
        uncollected: 0,
        invoice: {
          invoice: 0,
          invoiced: 0,
          uninvoiced: 0
        }
      }, // 费用
      caseNo: '', // 案号
      createUserId: '', // 申请人ID
      acceptCaseTime: '', // 收案时间
      itemListVisible: false, // 开票项目弹框
      itemList: [], // 开票项目列表
      invoiceTypeListVisible: false, // 发票类型弹框
      invoiceTypeList: [
        {
          value: 'VALUE_ADDED_TAX',
          text: '增值税普通发票'
        },
        {
          value: 'VAT_SPECIAL',
          text: '增值税专用发票'
        }
      ], // 发票类型列表
      title: '', // 发票抬头
      money: '', // 开票金额
      type: 'VALUE_ADDED_TAX', // 发票类型
      typeText: '增值税普通发票', // 发票类型名称
      item: '', // 开票项目
      taxpayersRegistrationNumber: '', // 纳税人识别号
      openingBank: '', // 基本开户银行名称
      bankAccount: '', // 基本开户账号
      address: '', // 注册场所地址
      phone: '', // 注册固定电话
      isAdd: 0, // 0新增  1修改
      progress: '',
      invoice: {},
      CC: [], // 抄送人列表
      Approval: [], // 审批人列表
      ccUser: {}, // 当前选中的抄送人
      approvalUser: {}, // 当前选中的审批人
      approvalUserComp: false, // 是否渲染审批人组件
      coopUser: null,
      caseTypeName: '其他非诉',
      operateBtnShow: false,
      companyShow: false,
      companyList: []
    }
  },
  computed: {
    ...mapState('user', ['organizationId', 'userInfo'])
  },
  methods: {
    ...mapActions('user', ['getOrganizationId']),
    ...mapMutations('oa', ['setOaTab']),
    select(options) {
      this.companyNameCopy = options.companyName
      this.$axios
        .get(
          `${this.$base}/management/getInfoByKey?companyName=${
            options.companyName
          }`
        )
        .then(res => {
          console.log(res.data.address)
          const obj = {
            companyName: options.companyName,
            address: res.data.address,
            creditCode: res.data.creditCode
          }
          this.handleCompanyListSelect(obj)
        })
    },
    searchInput() {
      var reg = /[\u4E00-\u9FA5]/g
      if (this.title && this.title.match(reg).length > 3) {
        this.$axios
          .get(`${this.$base}/management/business?name=${this.title}`)
          .then(res => {
            if (res.data !== '无结果') {
              this.companyList = res.data
            }
          })
      } else {
        this.companyList = []
      }
    },
    onFocus() {
      this.companyShow = true
    },
    onBlur() {
      setTimeout(() => {
        this.companyShow = false
      }, 100)
    },
    onDelete(value) {
      var money = this.money.toString()
      this.money = money.substring(0, money.length - 1)
    },
    onInput(value) {
      this.money = this.money + '' + value
    },
    // 返回列表页面
    backList(time = 1000) {
      if (this.$router.length <= 1) {
        console.log('直接返回到原生端')
        this.backToNative()
      } else {
        this.setOaTab('3')
        setTimeout(() => {
          this.$router.go(-1) // 返回上一层
        }, time)
      }
    },
    switchShow() {
      this.isShow = !this.isShow
    },
    // 公司列表点击事件
    switchCompanyList() {
      this.companyListVisible = !this.companyListVisible
    },
    handleCompanyListSelect(options) {
      // console.log(options)
      // this.switchCompanyList()
      this.title = options.companyName
      this.address = options.address
      this.taxpayersRegistrationNumber = options.creditCode
    },
    handleCompanyListSearchInput(title) {
      this.title = title
    },
    // 项目名称点击事件
    switchCaseList() {
      if (this.isAdd === 0) {
        this.caseListVisible = !this.caseListVisible
      }
    },
    handleCaseListSelect(options) {
      console.log(options)
      this.caseNo = options.caseNo
      this.caseName = options.label
      this.caseId = options.caseId
      this.title = options.createUserName
      this.createUserId = options.createUserId
      this.acceptCaseTime = this.timestampToTime(
        parseInt(options.acceptCaseTime)
      )
      // 根据案件id获取案件信息
      this.$axios
        .get(`${this.$base}/management/legal/case/${options.caseId}/finance`)
        .then(res => {
          this.price = res.data
        })
      this.caseTypeName = options.caseTypeName
      this.getUsers()
      this.switchCaseList()
    },
    // 时间戳转换成时间
    timestampToTime(cjsj) {
      var date = new Date(cjsj) // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + '-'
      var M =
        (date.getMonth() + 1 < 10
          ? '0' + (date.getMonth() + 1)
          : date.getMonth() + 1) + '-'
      var D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
      // var h =
      //   ' ' + (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
      // var m =
      //   date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
      return Y + M + D
    },

    // 获取开票类型
    getItme() {
      this.$axios
        .get(`${this.$base}/management/finance/category?divisible=true`)
        .then(res => {
          res.data.data.forEach((item, index) => {
            this.itemList.push({
              value: item.name,
              text: item.name
            })
          })
          if (this.isAdd === 0) {
            this.item = res.data.data[0].name
          }
        })
    },
    // 开票项目点击事件
    swtichItemList() {
      this.itemListVisible = !this.itemListVisible
    },
    handleItemListSelect(options) {
      this.item = options.value
      this.swtichItemList()
    },
    // 发票类型点击事件
    swtichInvoiceTypeList() {
      this.invoiceTypeListVisible = !this.invoiceTypeListVisible
    },
    handleInvoiceTypeListSelect(options) {
      this.typeText = options.text
      this.type = options.value
      this.swtichInvoiceTypeList()
    },
    // 获取审批人和抄送人
    async getUsers() {
      this.approvalUserComp = false
      var params = {
        organizationId: this.organizationId,
        caseTypeName: '默认',
        type: 'INVOICE_RECORD_ADD'
      }
      const res = await this.$axios.post(
        `${this.$base}/management/org/advice/user`,
        params
      )
      this.approvalUserComp = true
      const { code, data } = res.data
      if (code === 200 && data.length > 0) {
        const arr = [] // 审批
        const arrlist = [] // 抄送
        data.forEach(item => {
          if (item.type === 'APPROVER') {
            arr.push({
              label: item.userName,
              userid: item.userId,
              type: 'APPROVER',
              avatarUrl: item.userName
            })
          } else if (item.type === 'RECIPIENT') {
            arrlist.push({
              label: item.userName,
              userid: item.userId,
              type: 'RECIPIENT',
              avatarUrl: item.userName
            })
          }
        })
        this.CC = arrlist
        this.Approval = arr
        if (this.isAdd === 0) {
          this.ccUser = arrlist[0]
          this.approvalUser = arr[0]
        }
      }
    },
    /**
     * 处理审批流程组件返回的审批人和抄送人
     * 返回userList数组
     * 第一个元素是审批人
     * 第二个元素是抄送人
     */
    userSelect(userList) {
      this.approvalUser = userList[0]
      this.ccUser = userList[1]
    },
    // 协作人
    coopSelect(coopUser) {
      this.coopUser = coopUser
    },
    // 按钮事件
    save(progress) {
      this.$toast.loading({
        message: '加载中...',
        forbidClick: true
      })
      if (progress === 'DELETE') {
        this.deleteInvoice()
      } else {
        // 新增
        if (this.title === '') {
          this.$toast('请输入发票抬头')
          return
        }
        if (this.money === '') {
          this.$toast('请输入开票金额')
          return
        }
        if (this.isAdd === 0) {
          const userObj = []
          userObj[0] = {
            type: 'APPLICANT',
            user: {
              id: this.createUserId
            }
          }
          userObj[1] = {
            type: 'APPROVER',
            user: {
              id: this.approvalUser.userid
            }
          }
          userObj[2] = {
            type: 'RECIPIENT',
            user: {
              id: this.ccUser.userid
            }
          }
          if (this.coopUser) {
            userObj.push({
              type: 'APPROVER',
              user: {
                id: this.coopUser.id
              }
            })
          }
          const params = {
            title: this.title, // 发票抬头
            money: this.money, // 开票金额
            type: this.type, // 发票类型
            item: this.item, // 开票项目
            taxpayersRegistrationNumber: this.taxpayersRegistrationNumber, // 纳税人识别号
            openingBank: this.openingBank, // 基本开户银行名称
            bankAccount: this.bankAccount, // 基本开户账号
            address: this.address, // 注册场所地址
            phone: this.phone, // 注册固定电话
            approveRecordUsers: userObj,
            caseId: this.caseId,
            caseName: this.caseName,
            progress: progress,
            organization: {
              id: this.organizationId
            }
          }
          this.$axios
            .post(
              `${this.$base}/management/invoice/record/${this.organizationId}`,
              params
            )
            .then(res => {
              this.$toast.clear()
              if (res.data.code === 200) {
                this.$toast.success('提交成功')
                this.backList()
              } else {
                // this.$message.error(res.data.message)
              }
            })
            .catch(err => {
              this.$toast.clear()
              console.log(err)
            })
        } else {
          // 修改
          this.updateInvoice(progress)
        }
      }
    },

    // 删除事件
    deleteInvoice() {
      this.$dialog
        .confirm({
          title: '提示',
          message: '删除不可恢复！确定删除吗？'
        })
        .then(async () => {
          // on confirm
          const response = await this.$axios.post(
            `${this.$base}/management/invoice/record/delete/${this.invoice.id}`
          )
          const { code } = response.data
          if (code === 200) {
            this.$toast.success('删除成功')
            this.backList()
          }
        })
        .catch(() => {
          // on cancel
        })
    },

    // 更新事件
    updateInvoice(progress) {
      const userObj = []
      userObj[0] = {
        type: 'APPLICANT',
        user: {
          id: this.userInfo.id
        }
      }
      userObj.push({
        type: 'APPROVER',
        user: {
          id: this.approvalUser.userid
        }
      })
      userObj.push({
        type: 'RECIPIENT',
        user: {
          id: this.ccUser.userid
        }
      })
      const params = {
        title: this.title, // 发票抬头
        money: this.money, // 开票金额
        type: this.type, // 发票类型
        item: this.item, // 开票项目
        taxpayersRegistrationNumber: this.taxpayersRegistrationNumber, // 纳税人识别号
        openingBank: this.openingBank, // 基本开户银行名称
        bankAccount: this.bankAccount, // 基本开户账号
        address: this.address, // 注册场所地址
        phone: this.phone, // 注册固定电话
        approveRecordUsers: userObj,
        caseId: this.caseId,
        caseName: this.caseName,
        progress: progress,
        organization: {
          id: this.organizationId
        }
      }
      this.$axios
        .put(
          `${this.$base}/management/invoice/record/${this.invoice.id}/progress`,
          params
        )
        .then(res => {
          this.$toast.clear()
          if (res.data.code === 200) {
            this.$toast.success('提交成功')
            this.backList()
          } else {
            // this.$message.error(res.data.message)
          }
        })
        .catch(err => {
          this.$toast.clear()
          console.log(err)
        })
    },

    // 获取组织id
    async getId() {
      await this.getOrganizationId()
      await this.getUsers()
    },

    // 申请时间格式化
    getDateDiff(dateTimeStamp) {
      var result = ''
      var minute = 1000 * 60
      var hour = minute * 60
      var day = hour * 24
      var now = new Date().getTime()
      var diffValue = now - dateTimeStamp
      var dayC = diffValue / day
      var hourC = diffValue / hour
      var minC = diffValue / minute
      if (dayC >= 1) {
        result = '' + parseInt(dayC) + '天前'
      } else if (hourC >= 1) {
        result = '' + parseInt(hourC) + '小时前'
      } else {
        result = '' + parseInt(minC) + '分钟前'
      }
      return result
    },

    // 初始化传递过来的开票内容
    formatInvoice(invoice) {
      this.progress = invoice.progress
      this.caseNo = invoice.caseNo
      this.caseName = invoice.caseName
      this.caseId = invoice.caseId
      this.title = invoice.title
      this.money = invoice.money
      this.type = invoice.type
      this.item = invoice.item // 开票项目
      this.taxpayersRegistrationNumber = invoice.taxpayersRegistrationNumber // 纳税人识别号
      this.openingBank = invoice.openingBank // 基本开户银行名称
      this.bankAccount = invoice.bankAccount // 基本开户账号
      this.address = invoice.address // 注册场所地址
      this.phone = invoice.phone // 注册固定电话
      this.invoiceTypeList.map(item => {
        if (item.value === invoice.type) {
          this.typeText = item.text
        }
      })
      this.approveRecordUsers = invoice.approveRecordUsers
      this.approveRecordUsers.map(item => {
        if (item.type === 'APPROVER') {
          this.approvalUser = {
            label: item.user.nickName,
            userid: item.user.id,
            type: 'APPROVER',
            avatarUrl: item.user.avatarUrl
          }
        } else if (item.type === 'RECIPIENT') {
          this.ccUser = {
            label: item.user.nickName,
            userid: item.user.id,
            type: 'RECIPIENT',
            avatarUrl: item.user.avatarUrl
          }
        }
      })
    }
  },

  async created() {
    await this.getId()
    this.getItme()
    this.isAdd = parseInt(this.$route.query.isAdd)
    // isAdd 0表示新增  1表示修改
    if (this.isAdd === 1) {
      // const invoice = JSON.parse(this.$route.query.invoice)
      this.id = this.$route.query.commonId
      const res = await this.$axios.get(
        `${this.$base}/management/invoice/record/${this.$route.query.commonId}`
      )
      const { code, data } = res.data
      if (code === 200) {
        let avatarUrl = ''
        let applicantName = ''
        data.approveRecordUsers.map((item, i) => {
          if (item.type === 'APPLICANT') {
            avatarUrl = item.user.avatarUrl
            applicantName = item.user.nickName
          }
        })
        data.avatarUrl = avatarUrl
        data.applicantName = applicantName
        // 申请时间
        data.useTime = this.timestampToTime(data.createTime)
        data.useTimeText = this.getDateDiff(data.createTime)
      } else {
        this.$notify('找不到数据')
      }
      this.invoice = data
      this.$toast.loading({
        message: '加载中...',
        forbidClick: true
      })
      // 根据案件id获取案件信息
      this.$axios
        .get(`${this.$base}/management/legal/case/${data.caseId}/finance`)
        .then(res => {
          this.$toast.clear()
          this.price = res.data
        })
      this.formatInvoice(data)
      this.operateBtnShow = true
    } else {
      this.operateBtnShow = true
      this.switchCaseList()
    }
  },
  mounted() {}
}
</script>

<style lang="stylus">
.approval-title
  text-align center
  height 40px
  line-height 40px
  font-size 16px
  color rgba(69, 90, 100, 0.6)
.oa-add-invoice
  position relative
  padding-bottom 100px
  box-sizing border-box
  & .oa-add-invoice-line
    width 100%
    height 3px
    background-color rgba(242, 242, 242, 1)
  & .oa-add-invoice-top
    padding 0 20px
    box-sizing border-box
    & .top-invoice-title
      width 100%
      padding 10px 10px
      box-sizing border-box
      border-bottom 1px solid rgba(242, 242, 242, 1)
      font-size 16px
      font-weight 400
      div
        display -webkit-box
        -webkit-box-orient vertical
        -webkit-line-clamp 1
        overflow hidden
    & .top-invoice-content
      width 100%
      padding 10px 10px
      box-sizing border-box
      & .invoice-info
        display flex
        flex-direction row
        align-items center
        justify-content space-between
        padding 5px 0
        box-sizing border-box
        color #999999
        font-size 14px
  & .oa-add-invoice-center
    padding 0 20px
    box-sizing border-box
    & .invoice-info
      display flex
      flex-direction row
      align-items center
      justify-content space-between
      padding 0 10px
      height 45px
      box-sizing border-box
      font-size 14px
      border-bottom 1px solid rgba(242, 242, 242, 1)
      div
        & .van-cell
          padding 0px !important
      & .invoice-info-title
        position relative
        & .invoice-info-title-red
          position absolute
          left -8px
          color red
          font-size 14px
      & .invoice-info-title-input
        position relative
        flex 1
        & .search-suggestion
          position absolute
          right 0px
          top 30px
          width calc(100% - 20px)
          background #ffffff
          max-height 240px
          overflow-y auto
          box-shadow 0px 1px 8px 1px rgba(64, 180, 233, 0.23)
          border-radius 0 0 4px 4px
          z-index 10
          .suggestion-list-item
            display flex
            justify-content space-between
            align-items center
            padding 8px 18px
            .name
              font-size 14px
              color #555
            .attr
              font-size 13px
              color #888
            .attr.tag
              background #3EB3F0
              color #fff
              padding 0 2px
              border-radius 2px
  & .oa-approval
    border-top 10px solid rgba(242, 242, 242, 1)
    padding 20px
    box-sizing border-box
    position relative
    & .oa-approval-title
      font-size 14px
      color #CCCCCC
    & .oa-approval-step
      height 80px
      padding 0 20px
      display flex
      flex-direction row
      align-items center
      justify-content space-between
      border-bottom 1px solid rgba(242, 242, 242, 1)
      font-size 14px
      & .oa-approval-step-div
        display flex
        flex-direction row
        align-items center
        & .oa-approval-step-dot
          width 10px
          height 10px
          border-radius 5px
          background rgba(242, 242, 242, 1)
          margin-right 10px
      & .oa-approval-userInfo
        display flex
        flex-direction column
        align-items flex-end
        img
          width 30px
          height 30px
          border-radius 15px
    & .oa-approval-step-line
      width 2px
      height 80px
      background rgba(242, 242, 242, 1)
      position absolute
      bottom 60px
      left 44px
  & .oa-add-invoice-bottom
    width 100%
    background #FFFFFF
    position fixed
    bottom 0px
    right 0px
    padding 10px 20px
    box-sizing border-box
    display flex
    flex-direction row
    justify-content flex-end
    button
      width 80px
      height 50px
      border-radius 5px
      font-size 16px
    & .oa-button-save
      background none
      border none
      color #0079FE
    & .oa-button-apply
      background #0079FE
      border none
      color #FFFFFF
    & .oa-button-delete
      background none
      border 1px solid #0079FE
      color #0079FE
</style>
